<template>
  <div>
    <AllMyAppsModal />
    <AddNewToolModal />
    <CreateNewAdModal />
    <NovedadPopup />
    <InstructivoPopup />

    <div class="landing-loading" v-if="mostrarImagen">
      <transition name="fade-fast">
        <div v-show="mostrarImagen">
          <img v-if="$usuario.imagen == null" src="../assets/img/default.png" />
          <img
            v-if="$usuario.imagen != null"
            :src="$localurl + '/' + $usuario.imagen"
          />
          <transition name="fade-fast">
            <p class="landing-loading-status">
              {{ mostrarListo ? "Listo!" : "Cargando" }}
            </p>
          </transition>
        </div>
      </transition>
    </div>
    <div class="landing-container" v-if="mostrarLanding">
      <div class="landing-hero">
        <Header />
        <div class="hero-content">
          <div
            v-infocus="'showElement'"
            class="hidden hidden-left hero-message"
          >
            <p>Bienvenido,</p>
            <p>Nos encanta tenerte de vuelta</p>
          </div>
          <HeroTools />
        </div>
      </div>
      <div :class="['landing-body__background']">
        <div :class="[{'landing-body__background-opacity': showModal}]"></div>
        <div class="landing-body">
          <section class="landing-body-announcements">
            <div class="your-summary__header">
              <div>
                <h2>Tu resumen</h2>
                <p>Novedades relacionadas a tu perfil</p>
              </div>
              <button
                class="your-summary__add-btn"
                v-if="userIsPerformance()"
                @click="openCreateNewAdModal"
              >
                Agregar <img src="../assets/img/icons/add.svg" alt="agregar" />
              </button>
            </div>
            <div class="announcements-container">
              <Anuncio
                v-for="anuncio in anuncios"
                :key="anuncio.id"
                :anuncio="anuncio"
              />
              <NewPOG v-if="$usuario.rolPOG != null"></NewPOG>
              <NewCYO v-if="$usuario.rolCYO != null"></NewCYO>
              <ModifyYourProfileCard />
            </div>
          </section>
          <section class="landing-body-academy" v-if="false">
            <div class="academy-header">
              <div>
                <h2>Academia Agro</h2>
                <p>Aprende a utilizar las herramientas BASF al máximo</p>
              </div>
              <a @click="showAllCourses = !showAllCourses">{{
                showAllCourses ? "Ver menos intructivos" : "Ver mas intructivos"
              }}</a>
            </div>
            <div class="cards-container">
              <Academia
                v-for="(curso, index) in filteredCourses"
                v-bind:key="'curso_' + index"
                :curso="curso"
              />
            </div>
          </section>
          <section>
            <div class="news-header">
              <div>
                <h2>Instructivos</h2>
                <p>
                  Aprende a utilizar las herramientas BASF con videos y
                  tutoriales.
                </p>
              </div>
              <button
                class="your-summary__add-btn"
                v-if="userIsPerformance()"
                @click="abrirInstructivos()"
              >
                Agregar <img src="../assets/img/icons/add.svg" alt="agregar" />
              </button>
            </div>

            <div class="cards-container">
              <Instructivo
                v-for="instructivo in instructivos"
                :key="instructivo.id"
                :instructivo="instructivo"
              />
            </div>
          </section>
          <section class="landing-body-news">
            <div class="news-header">
              <div>
                <h2>Novedades BASF</h2>
                <p>¡Enterate de lo último!</p>
              </div>
              <button
                class="your-summary__add-btn"
                v-if="
                  $usuario.email == 'ana-karen.spioussas@basf.com' ||
                    $usuario.email == 'maria-ana.laguzzi@basf.com' ||
                    $usuario.email == 'gustavo.saldania@basf.com' ||
                    $usuario.email == 'jeronimo.carmody-fernandez@basf.com' ||
                    userIsPerformance()
                "
                @click="abrirNovedades()"
              >
                Agregar <img src="../assets/img/icons/add.svg" alt="agregar" />
              </button>
            </div>
            <div class="cards-destacadas-container">
              <NovedadDestacada
                v-for="novedad in novedadesDestacadas"
                :key="novedad.id"
                :novedad="novedad"
                @link-open="linkOpen"
                @update="getNovedades"
              ></NovedadDestacada>
            </div>

            <div class="cards-container">
              <Novedad
                v-for="novedad in novedadesSinDestacadas"
                :key="novedad.id"
                :novedad="novedad"
                @link-open="linkOpen"
              />
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="landing-footer">
      <div class="landing-footer-row">
        <label>Seguinos</label>
        <div class="landing-footer-redes">
          <img
            src="../assets/img/redes/basf.svg"
            @click="irARedes('https://agriculture.basf.com/ar/es.html')"
          />
          <img
            src="../assets/img/redes/fb.svg"
            @click="irARedes('https://www.facebook.com/BASF.AgroAR/')"
          />
          <img
            src="../assets/img/redes/tw.svg"
            @click="irARedes('https://twitter.com/BASF_Agro_ARG')"
          />
          <img
            src="../assets/img/redes/linkedin.svg"
            @click="irARedes('https://www.linkedin.com/company/basf/')"
          />
          <img
            src="../assets/img/redes/ig.svg"
            @click="irARedes('https://www.instagram.com/basfagro_arg/')"
          />
          <img
            src="../assets/img/redes/yt.svg"
            @click="
              irARedes(
                'https://www.youtube.com/channel/UCoDGtUf4DcRCEUZwPQODi8A'
              )
            "
          />
        </div>
      </div>
      <div class="landing-footer-row">
        <label>Contactános</label>
        <div>
          <div class="landing-footer-contacto" @click="abrirWhatsapp()">
            <img src="../assets/img/botones/leer_mas.png" />
            <label>Contactános vía Whatsapp</label>
          </div>
          <div class="landing-footer-contacto" @click="abrirCallCenter()">
            <img src="../assets/img/botones/leer_mas.png" />
            <label>Call Center: 0800-555-2273</label>
          </div>
          <div
            class="landing-footer-contacto"
            @click="
              irARedes('https://agriculture.basf.com/ar/es/legal/contacto.html')
            "
          >
            <img src="../assets/img/botones/leer_mas.png" />
            <label>Formulario de contacto</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import HeroTools from "../components/landing/HeroTools.vue";
import Anuncio from "../components/Anuncio.vue";
import Academia from "../components/Academia.vue";
import NovedadDestacada from "../components/novedad/NovedadDestacada.vue";
import Novedad from "../components/novedad/Novedad.vue";

import Instructivo from "../components/Instructivo.vue";

import AllMyAppsModal from "../components/landing/AllMyAppsModal.vue";
import AddNewToolModal from "../components/landing/AddNewToolModal.vue";
import CreateNewAdModal from "../components/landing/CreateNewAdModal.vue";
import NewPOG from "../components/tarjetas/NewPOG.vue";
import NewCYO from "../components/tarjetas/NewCYO.vue";
import NovedadPopup from "../components/novedad/NovedadPopup.vue";
import InstructivoPopup from "../components/InstructivoPopup.vue";
import ModifyYourProfileCard from "../components/landing/ModifyYourProfileCard.vue";
import helpers from "@/mixins/helpers";

export default {
  components: {
    Header,
    HeroTools,
    Anuncio,
    Academia,
    NovedadDestacada,
    Novedad,
    Instructivo,
    AllMyAppsModal,
    AddNewToolModal,
    CreateNewAdModal,
    NewPOG,
    NewCYO,
    NovedadPopup,
    InstructivoPopup,
    ModifyYourProfileCard
  },
  mixins: [helpers],
  data() {
    return {
      mostrarLanding: true,
      mostrarListo: false,
      mostrarImagen: false,
      user: {},
      anuncios: [],
      novedades: [],
      instructivos: [],
      showAllCourses: false,
      cursos: [
        {
          title: "Las 5 claves para el uso correcto de Mi Cuenta Corriente",
          descripcion:
            "No podrás cargar tus ventas POG pasada la fecha límite Leer mas"
        },
        {
          title: "Las 5 claves para el uso correcto de Mi Cuenta Corriente",
          descripcion:
            "No podrás cargar tus ventas POG pasada la fecha límite Leer mas"
        },
        {
          title: "Las 5 claves para el uso correcto de Mi Cuenta Corriente",
          descripcion:
            "No podrás cargar tus ventas POG pasada la fecha límite Leer mas"
        },
        {
          title: "Las 5 claves para el uso correcto de Mi Cuenta Corriente",
          descripcion:
            "No podrás cargar tus ventas POG pasada la fecha límite Leer mas"
        },
        {
          title: "Las 5 claves para el uso correcto de Mi Cuenta Corriente",
          descripcion:
            "No podrás cargar tus ventas POG pasada la fecha límite Leer mas"
        }
      ],
      imageUrl:
        "https://images.unsplash.com/photo-1500382017468-9049fed747ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80",
      showModal: false
    };
  },
  mounted() {
    this.getAnuncios();
    this.getNovedades();
    this.getInstructivos();
    this.$eventHub.$on("anuncio-nuevo", () => this.getAnuncios());
    this.$eventHub.$on("novedad-nuevo", () => this.getNovedades());
    this.$eventHub.$on("instructivo-nuevo", () => this.getInstructivos());
    this.$eventHub.$on("herramienta-nueva", herramienta =>
      this.nuevaHerramienta(herramienta)
    );
    this.user = this.$usuario;
  },
  computed: {
    novedadesDestacadas() {
      const destacadas = this.novedades.filter(novedad => novedad.destacar);
      return destacadas;
    },
    novedadesSinDestacadas() {
      const sinDestacadas = this.novedades.filter(novedad => !novedad.destacar);
      return sinDestacadas;
    }
  },
  methods: {
    linkOpen(data) {
      this.showModal = data;
    },
    irA(text) {
      this.postMetrica(text);
      const that = this;
      if (text != "planes") {
        let token = this.$cookies.get("token-landing");
        if (token == null) {
          token = localStorage.getItem("token-landing");
        }
        let ruta = "";
        switch (text) {
          case "mialta":
            ruta = this.$mialta_url + "/landing/" + token;
            break;
          case "cyo":
            ruta = this.$cyo_url + "/landing/" + token;
            break;
          case "cc":
            if (this.$dev) {
              ruta =
                "https://dev.micuenta.basf.com.ar/cuenta-corriente/landing/" +
                token;
            } else {
              ruta =
                "https://micuenta.agro.basf.com/cuenta-corriente/landing/" +
                token;
            }
            break;
          case "pog":
            ruta = this.$pog_url + "/landing/" + token;
            break;
          case "simulador":
            ruta = this.$dev
              ? "https://dev.micuenta.basf.com.ar/simulador"
              : "https://micuenta.agro.basf.com/simulador";
            if (this.$usuario.email.toLowerCase().includes("basf.com")) {
              ruta =
                ruta +
                "/plancomercial/planificacion?auth=" +
                token +
                "&admin=true";
            } else ruta = ruta + "/landing?auth=" + token;
            break;
          case "documentos":
            if (this.$dev) {
              ruta =
                "https://dev.micuenta.basf.com.ar/misdocumentos/token/" + token;
            } else {
              ruta =
                "https://micuenta.agro.basf.com/misdocumentos/token/" + token;
            }
            break;
          default:
            break;
        }
        if (ruta != "") {
          this.mostrarLanding = false;
          setTimeout(function() {
            that.mostrarImagen = true;
            setTimeout(function() {
              that.mostrarListo = true;
              window.location.href = ruta;
            }, 700);
          }, 800);
        }
      } else {
        this.mostrarLanding = false;
        this.mostrarImagen = true;
        this.$axios
          .get(this.$localurl + "/api/planes")
          .then(function(response) {
            console.log(response);
            that.mostrarListo = true;
            window.location.href = response.data;
          })
          .catch(function(response) {
            console.log(response);
          });
      }
    },
    irARedes(ruta) {
      window.open(ruta, "_blank").focus();
    },
    nuevaHerramienta(herramienta) {
      if (this.configuracion.herramientas.some(h => h.id == herramienta.id)) {
        this.configuracion.herramientas = this.configuracion.herramientas.filter(
          h => h.id != herramienta.id
        );
      }
      this.configuracion.herramientas.push(herramienta);
      this.guardarConfiguracion();
    },
    openAllMyAppsModal() {
      document.body.classList.add("body-no-scroll");
      this.$eventHub.$emit("open-all-my-apps-modal", true);
    },
    openCreateNewAdModal() {
      document.body.classList.add("body-no-scroll");
      this.$eventHub.$emit("open-create-new-ad-modal", true);
    },
    guardarConfiguracion() {
      const that = this;
      this.$axios
        .put(this.$localurl + "/api/configuracion", this.configuracion)
        .then(function(response) {
          that.configuracion = {};
          that.$nextTick(() => {
            that.configuracion = response.data;
          });
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    getAnuncios() {
      const that = this;
      this.anuncios = [];
      this.$axios
        .get(this.$localurl + "/api/anuncios")
        .then(function(response) {
          if (response.data != "") {
            that.anuncios = response.data;
          }
        })
        .catch(function(error) {
          console.error(error)
        });
    },
    abrirWhatsapp() {
      window.open("https://wa.me/5491134215552", "_blank").focus();
    },
    getNovedades() {
      const that = this;
      this.novedades = [];
      this.$axios
        .get(this.$localurl + "/api/novedades")
        .then(function(response) {
          if (response.data) {
            that.novedades = response.data;
            
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    getInstructivos() {
      const that = this;
      this.instructivos = [];
      this.$axios
        .get(this.$localurl + "/api/instructivos")
        .then(function(response) {
          if (response.data) {
            that.instructivos = response.data;
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    abrirNovedades() {
      this.$eventHub.$emit("novedad-abrir");
    },
    abrirInstructivos() {
      this.$eventHub.$emit("instructivo-abrir");
    },
    abrirCallCenter() {
      window.open("tel:0800-555-2273", "_blank").focus();
    },
    userIsPerformance() {
      if (
        (this.$usuario.rolClientes != null &&
          this.$usuario.rolClientes.detalle == "Performance") ||
        (this.$usuario.rolCYO != null &&
          this.$usuario.rolCYO.detalle == "Performance") ||
        (this.$usuario.rolPOG != null &&
          this.$usuario.rolPOG.detalle == "Performance") ||
        (this.$usuario.rolCC != null &&
          this.$usuario.rolCC.detalle == "Performance")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  // computed: {
  //   heroImage: function() {
  //     return (
  //       "background: linear-gradient(260deg,rgba(0, 0, 0, 0.21) 17.9%,rgba(0, 0, 0, 0.8) 73.92%), url(" +
  //       this.imageUrl +
  //       "), lightgray;  background-repeat: no-repeat;  background-size: cover;"
  //     );
  //   },
  //   filteredCourses() {
  //     return this.showAllCourses ? this.cursos : this.cursos.slice(0, 3);
  //   }
  // }
};
</script>
<style scoped src="../assets/css/app.css"></style>
<style scoped src="../assets/css/views/landing-new.css"></style>
