<template>
  <div class="hero-options-container">
    <div
      class="option-card permisos-card"
      v-if="userIsPerformance"
      @click="irAPermisos"
    >
      <img
        class="icon_lg"
        src="../../assets/img/icons/permisos_icon.png"
        alt="Permisos"
      />
      <p>Permisos</p>
    </div>

    <div
      class="option-card micyo-card"
      v-if="$usuario.rolCYO != null"
      @click="chequearIrA('cyo')"
    >
      <img
        class="icon_lg"
        src="../../assets/img/icons/micyo_icon_white.png"
        alt="mi cuenta & Orden"
      />
      <p>Mi Cuenta<br />& Orden</p>
    </div>

    <div
      class="option-card micc-card"
      v-if="$usuario.rolCC != null"
      @click="chequearIrA('cc')"
    >
      <img
        class="icon_lg"
        src="../../assets/img/icons/micc_icon_white.png"
        alt="mi cuenta corriente"
      />
      <p>Mi Cuenta<br />Corriente</p>
    </div>

    <div
      v-if="$usuario.rolPedidos != null"
      class="option-card misimulador-card"
      @click="goTo('mispedidos')"
    >
      <img
        class="icon_lg"
        src="../../assets/img/landing/mis-pedidos.png"
        alt="Mis Pedidos"
      />
      <p>Mis<br />Pedidos</p>
    </div>

    <div
      class="option-card mipog-card"
      v-if="$usuario.rolPOG != null"
      @click="goTo('pog')"
    >
      <img
        class="icon_lg"
        src="../../assets/img/icons/mipog_icon_white.png"
        alt="POG"
      />
      <p>Mi<br />POG</p>
    </div>

    <div
      class="option-card misimulador-card"
      v-if="$usuario.rolPlanComercial != null"
      @click="goTo('simulador')"
    >
      <img
        class="icon_lg"
        src="../../assets/img/icons/paper.svg"
        alt="Mi Plan comercial"
      />
      <p>Mi Plan<br />Comercial</p>
    </div>

    <div
      class="option-card mi-tienda-card"
      v-if="miTiendaToken"
      @click="goTo('mitienda')"
    >
      <img
        class="icon_lg"
        src="../../assets/img/icons/shop.svg"
        alt="Mi tienda"
      />
      <p>Mi Tienda</p>
    </div>

    <div class="option-card web-oficial-card" @click="goTo('weboficial')">
      <img
        class="icon_lg"
        src="../../assets/img/icons/world.svg"
        alt="Web oficial"
      />
      <p>Web Oficial</p>
    </div>

    <div
      class="option-card metricas-card"
      v-if="userIsPerformance"
      @click="irAMetricas"
    >
      <img
        class="icon_lg"
        src="../../assets/img/icons/metricas_icon.png"
        alt="Metricas"
      />
      <p>Métricas</p>
    </div>

    <div @click="openAllMyAppsModal" class="option-card herramientas-card">
      <div class="tools-card-header">
        <img
          class="tools_icon"
          src="../../assets/img/landing/todas_herramientas.png"
          alt="tools icon"
        />
        <img
          class="icon_lg tools_icon_add"
          src="../../assets/img/icons/add_icon_white.png"
          alt="mycyo_icon"
        />
      </div>
      <p>Todas Mis Apps</p>
    </div>
    <SelectClientPopup
      ref="selectClientPopup"
      :title="irATitle"
      :modulo="irAModulo"
      :callback="soldToPopupCallback"
    ></SelectClientPopup>
  </div>
</template>
<script>
import SelectClientPopup from "../SelectClientPopup.vue";
import helpers from "@/mixins/helpers";

export default {
  components: { SelectClientPopup },
  mixins: [helpers],
  data() {
    return {
      miTiendaToken: false,
      userIsPerformance: false,
      user: null,
      irAModulo: null,
      irATitle: null
    };
  },
  mounted() {
    const that = this;
    this.setUserIsPerformance();
    this.getMITiendaBASFURL();
    this.$eventHub.$on("update", usuario => {
      that.user = usuario;
      that.setUserIsPerformance();
      this.$forceUpdate();
    });
  },
  methods: {
    // Llamar a la función desde el componente secundario
    openAllMyAppsModal() {
      this.$eventHub.$emit("open-all-my-apps-modal", true); // Emitir evento para abrir el modal
    },
    getMITiendaBASFURL() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/mitienda")
        .then(function(response) {
          that.miTiendaToken = response.data;
        })
        .catch(function() {});
    },
    setUserIsPerformance() {
      if (
        (this.$usuario.rolClientes != null &&
          this.$usuario.rolClientes.detalle == "Performance") ||
        (this.$usuario.rolCYO != null &&
          this.$usuario.rolCYO.detalle == "Performance") ||
        (this.$usuario.rolPOG != null &&
          this.$usuario.rolPOG.detalle == "Performance") ||
        (this.$usuario.rolCC != null &&
          this.$usuario.rolCC.detalle == "Performance")
      ) {
        this.userIsPerformance = true;
      } else {
        this.userIsPerformance = false;
      }
    },
    chequearIrA(text) {
      this.irAModulo = text;
      switch (text) {
        case "cc":
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            this.goTo(text);
          } else {
            this.irATitle = "cuenta corriente";
            this.$refs.selectClientPopup.open();
          }
          break;
        case "cyo":
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            this.goTo(text);
          } else {
            const that = this;
            this.$axios
              .get(this.$localurl + "/api/cyo/muchos-clientes")
              .then(function() {
                that.goTo(text);
              })
              .catch(function() {
                that.irATitle = "cuenta y orden";
                that.$refs.selectClientPopup.open();
              });
          }
          break;
        default:
          break;
      }
    },
    goTo(module) {
      this.goToModule(module);
    },
    irAPermisos() {
      this.$router.push("/permisos");
    },
    irAMetricas() {
      this.$router.push("/metricas");
    },
    soldToPopupCallback() {
      this.goTo(this.irAModulo);
    }
  }
};
</script>
<style src="../../assets/css/components/hero-tools.css"></style>
