<script>
export default {
  name: "NewCYO",
  components: {},
  props: {},
  data() {
    return {
      mostrar: true,
      titulo: "",
      subtitulo: ""
    };
  },
  created() {},
  mounted() {
    this.getCYO();
  },
  computed: {},
  methods: {
    ir() {
      let token = localStorage.getItem("token");
      window.location.href = this.$cyo_url + "/landing/" + token;
    },
    getCYO() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/cyo/facturas/pendientes")
        .then(function(response) {
          console.log(response);
          if (response.data != null && response.data.length > 0) {
            let liquidaciones = response.data.filter(
              r => r.type == "Liquidacion"
            );
            let adquisiciones = response.data.filter(
              r => r.type == "Adquisicion"
            );
            let fungibles = response.data.filter(r => r.type == "Fungible");
            let distribuidores = [];
            response.data.forEach(r => {
              if (
                !distribuidores.some(d => d.nombre == r.distribuidor.nombre)
              ) {
                distribuidores.push(r.distribuidor);
              }
            });

            let text = "Tenés ";
            if (liquidaciones.length > 0) {
              text = text + liquidaciones.length + " liquidaciones, ";
            }
            if (adquisiciones.length > 0) {
              text = text + adquisiciones.length + " adquisiciones, ";
            }
            if (fungibles.length > 0) {
              text = text + fungibles.length + " fungibles, ";
            }

            if (text === "Tenés ") {
              that.mostrar = false;
            }

            that.titulo = text.substr(0, text.length - 2);
            let pos = that.titulo.lastIndexOf(", ");
            that.titulo =
              that.titulo.substring(0, pos) +
              " y" +
              that.titulo.substring(pos + 1);

            that.subtitulo = "El cliente " + distribuidores[0].nombre;
            if (distribuidores.length > 1) {
              that.subtitulo =
                that.subtitulo +
                " y " +
                distribuidores.length +
                " más, están esperando la aprobación de sus cargas";
            } else {
              that.subtitulo =
                that.subtitulo + " está esperando la aprobación de su carga";
            }
          } else {
            that.mostrar = false;
          }
        })
        .catch(function(response) {
          console.log(response);
          that.mostrar = false;
        });
    }
  }
};
</script>

<template>
  <div
    v-if="mostrar"
    v-infocus="'showElement'"
    class="hidden hidden-down anuncio-container"
  >
    <img
      class="icon_lg circular-img"
      src="../../assets/img/icons/blue-cyo.svg"
      alt="profile image"
    />
    <div class="anuncio-info">
      <div class="anuncio-info__header">
        <h2>
          {{ titulo }}
        </h2>
      </div>
      <p>
        {{ subtitulo }}
      </p>
      <a @click="ir()">Leer mas</a>
    </div>
  </div></template
>

<style scoped src="../../assets/css/components/new-cyo.css"></style>
