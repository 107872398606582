<template>
  <div class="container">
    <div class="text-content">
      <h2>{{ novedad.titulo }}</h2>
      <p>
        {{ novedad.descripcion }}
      </p>
      <button @click="openModalLinks(novedad.links)">Ver mas</button>
    </div>
    <span class="material-symbols-outlined" @click="openModalDelete">
      delete
    </span>
    <img v-bind:src="creadorImage()" alt="imagen novedad" />
    <DeleteModal
      :show="showModalDelete"
      @delete="deleteNovedad"
      @close="closeModalDelete"
    ></DeleteModal>
    <LinkModal
      :show="show"
      :links="novedad.links"
      @close="closeModal"
    ></LinkModal>
  </div>
</template>

<script>
import LinkModal from "../LinkModal.vue";
import DeleteModal from "../DeleteModal.vue";

export default {
  components: { LinkModal, DeleteModal },
  props: {
    novedad: Object,
  },
  data() {
    return {
      show: false,
      showModalDelete: false,
      links: []
    };
  },
  methods: {
    creadorImage() {
      return !this.anuncio?.creador?.imagen
        ? this.$localurl + "/" + this.novedad.imagen
        : require("../../assets/img/default.png");
    },
    closeModal() {
      this.show = false;
      this.$emit("link-open", false);
    },
    openModalLinks(links) {
      this.show = false;
      console.log(links);
      if (links.length == 1) {
        window.open(links[0].url, "_blank", "noopener");
      } else {
        this.links = links;
        this.show = true;
        this.$emit("link-open", true);
      }
    },
    deleteNovedad() {
      const that = this;
      this.$axios
        .delete(this.$localurl + "/api/novedad/" + this.novedad.id)
        .then(function () {
          that.$emit("update");
        })
        .catch(function (response) {
          console.log(response);
        });
      this.closeModalDelete();
    },
    openModalDelete() {
      this.showModalDelete = true;
    },
    closeModalDelete() {
      this.showModalDelete = false;
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  height: 320px;
  position: relative;
  display: grid;
  place-content: center;
}
.text-content {
  width: 100%;
  max-width: 450px;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;
  display: grid;
  row-gap: 0.5rem;
}
h2 {
  margin: 0;
  font-size: 32px;
  text-transform: lowercase;
}
h2::first-letter {
  text-transform: uppercase;
}
p,
button {
  width: 100%;
  max-width: 380px;
  margin-inline: auto;
}
button {
  font-size: 1em;
  background: none;
  outline: none;
  border: none;
  color: #00a3d9;
  text-decoration: underline;
  cursor: pointer;
}
span {
  position: absolute;
  top: 2vh;
  right: 1vw;
  background: #F0F0F0;
  padding: 0.5vw;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}
img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  filter: brightness(0.5);
  border-radius: 12px;
}
</style>
